import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import React from 'react';

import Prayer7 from './components/prayer7';
import Image from './components/image';
import App from './App';

import './css/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
      <Helmet>
        <title>nexsu.net</title>
        <meta property="og:title" content="nexsu.net" />
        <meta property="og:description" content="nexsu.net is a windows 7 design inspired site for viewing my weather photos and videos in high quality!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://arina.lol/api/win7/acfile/1724080982866DSC_1108_1_result.JPG" />
        <meta property="og:url" content="https://nexsu.net" />
        <meta name="theme-color" content="#2be387"/>

        <meta name="twitter:card" content="summary_large_image"/>
            
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="314" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nexsu.net/" />
        <meta property="twitter:title" content="nexsu.net" />
        <meta property="twitter:description" content="nexsu.net is a windows 7 design inspired site for viewing my weather photos and videos in high quality!" />
        <meta property="twitter:image" content="https://arina.lol/api/win7/acfile/1724080982866DSC_1108_1_result.JPG" />
      </Helmet>
      <Router>
        <Routes>
          <Route path='/image/:id' element={<Image/>}/>
          <Route path='/prayer7' element={<Prayer7/>}/>
          <Route path='/' element={<App/>}/>
          <Route path='*' element={<App/>}/>
        </Routes>
      </Router>
  </>
);