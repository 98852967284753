import axios from "axios";
import { useEffect, useState } from "react";

const stopwatchtext = [
    "A stopwatch. It appears to be frozen.",
    "A stopwatch. It's ticking very slowly as it thaws.",
    "A stopwatch. It's ticking slowly as it thaws.",
    "A stopwatch. It's ticking just a bit slow as it warms up.",
    "A stopwatch. It's ticking perfectly.",
    "A stopwatch. It's ticking faster than it should and overheating.",
    "A stopwatch. It's ticking extremely fast and enminating extreme heat. It's going to shatter.",
    "A stopwatch. It's shattered time and now ticking backwards. The past begins to flow around it. It has cooled significantly.",
    "You can't go back now."]

const stopwatchglow = [0, 0, 0, 2, 4, 7, 12, 64, 128]
const stopwatchglowc = ['yellow', 'yellow', 'yellow', 'green', 'yellow', 'orange', 'red', 'aliceblue', 'white']
const stopwatchbg = ["radial-gradient(circle, rgba(57,97,167,1) 0%, rgba(9,9,121,1) 15%, rgba(2,0,36,1) 35%)",
    "radial-gradient(circle, rgba(28,108,154,1) 0%, rgba(6,11,125,1) 23%, rgba(2,0,36,1) 55%)",
    "radial-gradient(circle, rgba(28,152,154,1) 0%, rgba(6,11,125,1) 23%, rgba(2,0,36,1) 55%)",
    "radial-gradient(circle, rgba(77,181,49,1) 0%, rgba(6,11,125,1) 29%, rgba(2,0,36,1) 63%)",
    "radial-gradient(circle, rgba(255,239,97,1) 0%, rgba(6,11,125,1) 42%, rgba(2,0,36,1) 63%)",
    "radial-gradient(circle, rgba(196,144,57,1) 0%, rgba(44,9,121,1) 59%, rgba(2,0,36,1) 96%)",
    "radial-gradient(circle, rgba(227,168,69,1) 0%, rgba(255,83,83,1) 21%, rgba(106,9,121,1) 59%, rgba(2,0,36,1) 96%)",
    "radial-gradient(circle, rgba(130,255,251,1) 0%, rgba(9,9,121,1) 24%, rgba(2,0,36,1) 44%)",
    "black"
]

const Prayer7 = () => {

    const [stopwatchstate, setStopwatchstate] = useState(0)
    const [returntext, setReturntext] = useState("")

    const submitKey = (num) => {
        var val = document.getElementById(`prayer${num}`).value
        axios.post("https://arina.lol/api/prayer7", {value: val, step: stopwatchstate}).then((res) => {
            console.log(res.data)
            if (res.data.finalkey) {
                setStopwatchstate(8)
                setReturntext(res.data.text)
            } else {
                if (res.data.correctkey) {
                    setStopwatchstate(stopwatchstate + 1)
                    setReturntext(res.data.text)
                } else {
                    setStopwatchstate(0)
                    setReturntext(res.data.text)
                }

            }
        }).catch((err) => {
            console.error(err)
            setReturntext("Server error.")
        })
    }

    return (
        <>
            <div style={{background: `${stopwatchbg[stopwatchstate]}`, height: '100vh', width: '100%', display: 'grid', gridTemplateColumns: 'repeat(9, 11.11%)', gridTemplateRows: 'repeat(8, 12%)'}}>
                <p style={{gridColumnStart: 3, gridColumnEnd: 5, gridRow: '4', textAlign: 'center', color: 'aliceblue', textShadow: '1px 1px 3px black'}}><i>{stopwatchtext[stopwatchstate]}</i></p>
                <div style={{gridColumn: '5', gridRow: '5', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={`/images/bgs/stopwatchrotate-${stopwatchstate}.gif`} 
                    alt={`${stopwatchtext[stopwatchstate]}`}
                    title={`${stopwatchtext[stopwatchstate]}`}
                    style={{imageRendering: 'pixelated', aspectRatio: '1/1', color: 'white', filter: `drop-shadow(0px 0px ${(stopwatchglow[stopwatchstate])}px ${stopwatchglowc[stopwatchstate]})`}}
                    height={'100%'} />
                </div>
                <div style={{gridColumnStart: 4, gridColumnEnd: 7, gridRow: '6', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{color: 'aliceblue', textShadow: '1px 1px 3px black'}}>{returntext}</p>
                </div>
                <div style={{gridColumn: 2, gridRow: 3, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input type="text" placeholder="prayer1" id="prayer1" 
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate > 0 ? 1 : 0})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}} 
                     onClick={() => submitKey(1)}>Submit</button>
                </div>
                <div style={{gridColumn: 5, gridRow: 2, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 1} type="text" placeholder="prayer2" id="prayer2" 
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 1 ? 0 : 1})`}} />
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}} 
                    onClick={() => submitKey(2)}>Submit</button>
                </div>
                <div style={{gridColumn: 8, gridRow: 3, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 2} type="text" placeholder="prayer3" id="prayer3"
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 2 ? 0 : 1})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}} 
                     onClick={() => submitKey(3)}>Submit</button>
                </div>
                <div style={{gridColumn: 2, gridRow: 6, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 3} type="text" placeholder="prayer4" id="prayer4"
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 3 ? 0 : 1})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}}  
                    onClick={() => submitKey(4)}>Submit</button>
                </div>
                <div style={{gridColumn: 8, gridRow: 6, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 4} type="text" placeholder="prayer5" id="prayer5"
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 4 ? 0 : 1})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}}  
                    onClick={() => submitKey(5)}>Submit</button>
                </div>
                <div style={{gridColumn: 4, gridRow: 8, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 5} type="text" placeholder="prayer6" id="prayer6"
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 5 ? 0 : 1})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}}  
                    onClick={() => submitKey(6)}>Submit</button>
                </div>
                <div style={{gridColumn: 6, gridRow: 8, display: `${stopwatchstate === 7 ? 'none' : 'flex'}`, flexDirection: 'column', alignItems: 'center'}}>
                    <input disabled={stopwatchstate < 6} type="text" placeholder="prayer7" id="prayer7"
                    style={{width: '200%', zIndex: 2, textAlign: 'center', background: 'black', color: 'lime', padding: '10px 6px', border: 'double white 2px', outline: 'lime 1px solid', borderRadius: '5px', filter: `grayscale(${stopwatchstate === 6 ? 0 : 1})`}}/>
                    <button style={{width: '200%', border: 'solid black 3px', outline: 'solid white 1px', background: 'golden', cursor: 'pointer', borderRadius: '6px', paddingTop: '25px', marginTop: '-25px', zIndex: 1}}  
                    onClick={() => submitKey(7)}>Submit</button>
                </div>
            </div>
        </>
    )
}

export default Prayer7;